<template>
  <div
    class="jump-to-top"
    :class="classes">
    <button
      type="button"
      class="jump-to-top__link"
      title="Jump to top"
      aria-label="Jump to top"
      @click="scrollToTop">
      <span class="jump-to-top__icon">
        <a-icon icon="fa:regular:arrow-to-top" />
      </span>

      Back to Top
    </button>
  </div>
</template>

<script>
export default {
  name: "JumpToTop",
  props: {
    appear: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ( {
    scrollInstance: null,
    hidden: false,
  } ),
  computed: {
    classes () {
      const result = [];

      if ( this.appear && this.hidden ) {
        result.push( "jump-to-top--hidden" );
      }

      return result;
    },
  },
  mounted () {
    this.scrollHandler();

    window.addEventListener( "scroll", this.scrollHandler, { passive: true } );
  },
  unmounted () {
    window.removeEventListener( "scroll", this.scrollInstance );
  },
  methods: {
    scrollHandler () {
      this.hidden = document.documentElement.scrollTop < 100;
    },
    scrollToTop () {
      window.scrollTo( { top: 0, behavior: "smooth" } );
    },
  },
};
</script>

<style lang="scss" scoped>
.jump-to-top {
  z-index: 600;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  opacity: 1;
  transition: opacity 0.2s;
  border-radius: 5px;
  margin-bottom: 20px;

  .jump-to-top__link {
    color: #aaa;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline-block;
    cursor: pointer;

    &:hover,
    &:active {
      color: #999;
    }
  }

  &__icon {
    display: inline-block;
    margin-right: 3px;
    color: var( --color-primary-500 );
  }

  &--hidden {
    opacity: 0;
  }

  @media print {
    display: none;
  }
}
</style>
